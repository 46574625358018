import React, { useState, useEffect } from "react";
import { hubsList, hyderabadMandis, baseQueryUrl } from "../constants";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { convertToNumber } from "../utils";

const fetchProfits = async () => {
  const url = baseQueryUrl + "?investor=true";
  const res = await axios.get(url);

  // Group by load_id
  const obj = {};
  res.data.data.forEach((element) => {
    if (!obj.hasOwnProperty(element.load_id)) {
      obj[element.load_id] = element;
    } else {
      obj[element.load_id].profit_loss += convertToNumber(element.cm2_profit);
    }
  });
  return obj;
};

const fetchLoadsData = async (param, paramString, profits) => {
  const url = baseQueryUrl + "?load=all&sale=true&" + paramString + "=" + param;
  const res = await axios.get(url);

  // Clean-up the data and add profit
  res.data.data.forEach((element) => {
    element.sale = convertToNumber(element.sale);
    if (profits[element.load_id]) {
      const total_goats_sold = convertToNumber(
        profits[element.load_id].total_goats_sold
      );
      const goats_sold = convertToNumber(element.goats_sold);
      element.profit_loss =
        convertToNumber(profits[element.load_id].profit_loss) *
        (goats_sold / total_goats_sold);
      element.profit_loss = convertToNumber(element.profit_loss);
      element.load_type = profits[element.load_id].load_type;
    }
  });
  return res.data.data;
};

function HomePage({ setMandiOrHub, setIsHub, setData, setLoading }) {
  const [hubs, setHubs] = useState(hubsList);
  const [insideHydrabad, setInsideHydrabad] = useState(false);

  const navigate = useNavigate();

  const handelDataFetching = async (param, paramString) => {
    const profits = await fetchProfits();
    const loadsData = await fetchLoadsData(param, paramString, profits);

    const rowData = [];
    Object.entries(loadsData).forEach(([key, value]) => {
      rowData.push({ ...value });
    });
    setData(rowData);
    setLoading(false);
  };

  const handleClick = async (hub) => {
    if (hub.label === "Hyderabad") {
      setInsideHydrabad(true);
      setHubs(hyderabadMandis);
    } else if (hub.label === "Ziaguda" || hub.label === "Chengicherla") {
      setMandiOrHub(hub.label);
      setIsHub(false);
      setLoading(true);
      handelDataFetching(hub.label, "mandi");
      navigate("/mandi");
    } else {
      setMandiOrHub(hub.label);
      setIsHub(true);
      setLoading(true);
      handelDataFetching(hub.label, "hub");
      navigate("/mandi");
    }
  };

  useEffect(() => {
    setData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gmvhelper = async () => {
    const profits = await fetchProfits();
    const res = await fetchLoadsData("all", "hub", profits);
    setData(res);
    setLoading(false);
  };

  const handleGMVClick = async () => {
    setLoading(true);
    gmvhelper();
    navigate("/gmv");
  };

  return (
    <div className="hubContainer">
      {!insideHydrabad && (
        <div className="hub-item" onClick={handleGMVClick}>
          Total GMV
        </div>
      )}
      {hubs.map((hub) => (
        <div
          key={hub.id}
          className="hub-item"
          onClick={() => {
            handleClick(hub);
          }}
        >
          {hub.label}
        </div>
      ))}
    </div>
  );
}

export default HomePage;
