import React, { useEffect, useState } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { isDateBetween } from "../utils";

const columns = [
  {
    name: "load_id",
    header: <div className="table-header">Load Id</div>,
    type: "string",
    render: ({ value }) => <div className="table-cell">{value}</div>,
  },
  {
    name: "sale",
    header: <div className="table-header">Sale Value</div>,
    type: "string",
    render: ({ value }) => (
      <div className="table-cell">
        {value ? value.toLocaleString("en-IN") : value}
      </div>
    ),
  },
  {
    name: "profit_loss",
    header: <div className="table-header">Profits/Loss</div>,
    type: "string",
    render: ({ value }) => (
      <div className="table-cell">
        {value ? value.toLocaleString("en-IN") : value}
      </div>
    ),
  },
];

function MonthDetailPage({ mandiOrHub, data, loading, startDate, isHub }) {
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([]);

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    if (mandiOrHub === "") {
      navigate("/");
    }

    const givenStartDate = new Date(startDate);
    var firstDayOfMonth = new Date(
      givenStartDate.getFullYear(),
      givenStartDate.getMonth(),
      1
    );
    var lastDayOfMonth = new Date(
      givenStartDate.getFullYear(),
      givenStartDate.getMonth() + 1,
      0
    );

    // sum-up all the duplicate loads
    const loads = {};
    data.forEach((element) => {
      const elementCopy = { ...element };
      const itemDate = new Date(elementCopy.date);
      if (isDateBetween(firstDayOfMonth, lastDayOfMonth, itemDate)) {
        if (!loads.hasOwnProperty(elementCopy.load_id)) {
          loads[elementCopy.load_id] = elementCopy;
        } else {
          loads[elementCopy.load_id].sale += elementCopy.sale;
        }
      }
    });

    //   check if item.date is between startdate and end date
    const filteredData = [];
    Object.entries(loads).forEach(([key, value]) => {
      const itemDate = new Date(value.date);
      if (isDateBetween(firstDayOfMonth, lastDayOfMonth, itemDate)) {
        if (!isHub && value.load_type === "Partner-Load")
          value.profit_loss = Math.round(value.sale * 0.04);
        filteredData.push({ ...value });
      }
    });

    setRowData(filteredData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handelBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <div className="nav-continer">
        <BiArrowBack className="back" onClick={handelBack} />
        <div className="headings">
          {month[new Date(startDate).getMonth()]}
          {new Date(startDate).getFullYear()}
        </div>
      </div>
      <ReactDataGrid
        columns={columns}
        dataSource={rowData}
        showColumnMenuTool={false}
        sortable={false}
        loading={loading}
        style={{
          height: "85vh",
          margin: "20px",
          marginTop: "10px",
        }}
      />
    </div>
  );
}

export default MonthDetailPage;
