export const convertToNumber = (value) => {
  try {
    return Math.round(value);
  } catch (e) {
    console.error(e);
    return 0;
  }
};

export const isDateBetween = (startDate, endDate, dateToCheck) => {
  return dateToCheck >= startDate && dateToCheck <= endDate;
};
