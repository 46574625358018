import { useState } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";

import HomePage from "./pages/HomePage";
import HubDetailPage from "./pages/HubDetailPage";
import MonthDetailPage from "./pages/MonthDetailPage";
import GMVPage from "./pages/GMVPage";

function App() {
  const [mandiOrHub, setMandiOrHub] = useState("");
  const [isHub, setIsHub] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  return (
    <BrowserRouter>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <HomePage
              mandiOrHub={mandiOrHub}
              setMandiOrHub={setMandiOrHub}
              setIsHub={setIsHub}
              isHub={isHub}
              setData={setData}
              setLoading={setLoading}
            />
          }
        />
        <Route
          exact
          path="/mandi"
          element={
            <HubDetailPage
              isHub={isHub}
              mandiOrHub={mandiOrHub}
              setData={setData}
              loading={loading}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              data={data}
            />
          }
        />
        <Route
          path="/month"
          element={
            <MonthDetailPage
              isHub={isHub}
              mandiOrHub={mandiOrHub}
              data={data}
              loading={loading}
              startDate={startDate}
              endDate={endDate}
            />
          }
        />
        <Route
          path="/gmv"
          element={
            <GMVPage
              data={data}
              loading={loading}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setData={setData}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
