import React, { useEffect, useState } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { MONTHS, YEARS } from "../constants";
import { convertToNumber, isDateBetween } from "../utils";

const columns = [
  {
    name: "month",
    header: <div className="table-header">Month</div>,
    type: "string",
    render: ({ value }) => <div className="table-cell">{value}</div>,
  },
  {
    name: "total_gmv",
    header: <div className="table-header">Total GMV</div>,
    type: "string",
    render: ({ value }) => (
      <div className="table-cell">
        {value ? value.toLocaleString("en-IN") : value}
      </div>
    ),
  },
  {
    name: "profit",
    header: <div className="table-header">Profit</div>,
    type: "string",
    render: ({ value }) => (
      <div className="table-cell">
        {value ? value.toLocaleString("en-IN") : value}
      </div>
    ),
  },
  {
    name: "profit_percentage",
    header: <div className="table-header">Profit Percentage</div>,
    type: "string",
    render: ({ value }) => (
      <div className="table-cell">
        {value ? value.toLocaleString("en-IN") : value}%
      </div>
    ),
  },
];

function GMVPage({ data, loading, setData }) {
  const navigate = useNavigate();
  const [rowsData, setRowsData] = useState([]);

  useEffect(() => {
    const rows = [];
    if (data.length === 0) return;
    YEARS.forEach((year) => {
      MONTHS.forEach((month) => {
        // Months till current month
        if (new Date(`${year}-${month.value}-01`) < new Date()) {
          // Sum up all the sale till this month
          let gmv = 0;
          let profit = 0;
          data.forEach((item) => {
            const startDate = new Date(`${year}-${month.value}-01`);
            var firstDayOfMonth = new Date(
              startDate.getFullYear(),
              startDate.getMonth(),
              1
            );
            var lastDayOfMonth = new Date(
              startDate.getFullYear(),
              startDate.getMonth() + 1,
              0
            );
            if (
              isDateBetween(
                firstDayOfMonth,
                lastDayOfMonth,
                new Date(item.date)
              )
            ) {
              gmv += item.sale;

              if (item.hub === "Hyderabad") profit += item.sale * 0.04;
              else {
                if (!isNaN(item.profit_loss))
                  profit += convertToNumber(item.profit_loss);
              }
            }
          });

          rows.push({
            month: month.label + "-" + year,
            startDate: `${year}-${month.value}-01`,
            endDate: `${year}-${month.value}-31`,
            total_gmv: gmv,
            profit: profit,
            profit_percentage: (profit / gmv) * 100 || 0,
          });
        }
      });
    });
    setRowsData(rows);
  }, [data]);

  useEffect(() => {
    if (data.length === 0 && loading === false) navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handelBack = () => {
    setData([]);
    navigate(-1);
  };

  return (
    <div>
      <div className="nav-continer">
        <BiArrowBack className="back" onClick={handelBack} />
        <div className="headings">Total GMV</div>
      </div>
      <ReactDataGrid
        columns={columns}
        showColumnMenuTool={false}
        dataSource={rowsData}
        loading={loading}
        style={{
          height: "85vh",
          margin: "20px",
          marginTop: "10px",
        }}
      />
    </div>
  );
}

export default GMVPage;
