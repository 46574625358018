import React, { useEffect, useCallback, useState } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { useNavigate } from "react-router-dom";
import { MONTHS, YEARS } from "../constants";
import { BiArrowBack } from "react-icons/bi";
import { isDateBetween } from "../utils";

const columns = [
  {
    name: "month",
    // minWidth: 250,
    header: <div className="table-header">Month</div>,
    type: "string",
    render: ({ value }) => <div className="table-cell">{value}</div>,
  },
  {
    name: "sale_sum",
    minWidth: 250,
    header: <div className="table-header">GMV</div>,
    type: "string",
    render: ({ value }) => (
      <div className="table-cell">
        {value ? value.toLocaleString("en-IN") : value}
      </div>
    ),
  },
  {
    name: "profit_sum",
    minWidth: 250,
    header: <div className="table-header">Profit (CM2)</div>,
    type: "string",
    render: ({ value }) => (
      <div className="table-cell">
        {value ? value.toLocaleString("en-IN") : value}
      </div>
    ),
  },
];

function HubDetailPage({
  mandiOrHub,
  data,
  setData,
  setStartDate,
  setEndDate,
  loading,
  isHub,
}) {
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([]);

  const onRenderRow = useCallback((rowProps) => {
    const { onClick } = rowProps;

    rowProps.onClick = (event) => {
      setStartDate(rowProps.data.startDate);
      setEndDate(rowProps.data.endDate);
      navigate("/month");
      if (onClick) {
        onClick(event);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handelBack = () => {
    setData([]);
    navigate(-1);
  };

  useEffect(() => {
    if (mandiOrHub === "") {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const rows = [];

    if (data.length === 0) return;
    YEARS.forEach((year) => {
      MONTHS.forEach((month) => {
        if (new Date(`${year}-${month.value}-01`) < new Date()) {
          const givenStartDate = new Date(`${year}-${month.value}-01`);
          var firstDayOfMonth = new Date(
            givenStartDate.getFullYear(),
            givenStartDate.getMonth(),
            1
          );
          var lastDayOfMonth = new Date(
            givenStartDate.getFullYear(),
            givenStartDate.getMonth() + 1,
            0
          );
          let profit_sum = 0;
          let sale_sum = 0;
          data.forEach((element) => {
            const elementCopy = { ...element };
            const itemDate = new Date(elementCopy.date);
            if (isDateBetween(firstDayOfMonth, lastDayOfMonth, itemDate)) {
              if (!isHub && elementCopy.load_type === "Partner-Load")
                profit_sum += Math.round(elementCopy.sale * 0.04);
              else profit_sum += elementCopy.profit_loss;
              sale_sum += elementCopy.sale;
            }
          });

          rows.push({
            month: month.label + "-" + year,
            startDate: `${year}-${month.value}-01`,
            endDate: `${year}-${month.value}-31`,
            profit_sum: profit_sum,
            sale_sum: sale_sum,
          });
        }
      });
    });

    setRowData(rows);
  }, [data]);

  return (
    <div>
      <div className="nav-continer">
        <BiArrowBack className="back" onClick={handelBack} />
        <div className="headings">{mandiOrHub}</div>
      </div>
      <ReactDataGrid
        columns={columns}
        dataSource={rowData}
        showColumnMenuTool={false}
        sortable={false}
        onRenderRow={onRenderRow}
        loading={loading}
        style={{
          height: "85vh",
          margin: "20px",
          marginTop: "10px",
        }}
      />
    </div>
  );
}

export default HubDetailPage;
