export const hubsList = [
  {
    id: "Ajmer",
    label: "Ajmer",
  },
  { id: "Anantapur", label: "Anantapur" },
  { id: "Bagewadi", label: "Bagewadi" },
  { id: "Bangalore", label: "Bangalore" },
  { id: "Chennai", label: "Chennai" },
  { id: "Hyderabad", label: "Hyderabad" },
  { id: "Shahpur", label: "Shahpur" },
];

export const MONTHS = [
  { value: "12", label: "December" },
  { value: "11", label: "November" },
  { value: "10", label: "October" },
  { value: "09", label: "September" },
  { value: "08", label: "August" },
  { value: "07", label: "July" },
  { value: "06", label: "June" },
  { value: "05", label: "May" },
  { value: "04", label: "April" },
  { value: "03", label: "March" },
  { value: "02", label: "February" },
  { value: "01", label: "January" },
];

export const YEARS = [2023, 2022, 2021];

export const hyderabadMandis = [
  {
    id: "Ziaguda",
    label: "Ziaguda",
  },
  { id: "Chengicherla", label: "Chengicherla" },
];

export const baseQueryUrl =
  // "https://script.google.com/macros/s/AKfycbwjfyJNeKvNEdI04XjVjgbEmmKtlVJZgtYBqEk8suPpeCtT-9FdtTMowHCp_wyQvBPjOQ/exec";
  "https://script.google.com/macros/s/AKfycbxg6f0xKEFfR0SBMvm_Iv4dKruo1JVU9oGWjT0VBSWmTnQkgtUeEO88iu3EVxLNRdRe/exec"
